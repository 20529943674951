<template>
  <div v-if="isshow">
    <div
      class="banner"
      id="aboutus_banner1"
      :style="{ backgroundImage: 'url(' + banner + ')' }"
    >
      <!-- <img :src="banner" /> -->
      <span
        >{{ $t("indexnav.Corporate Profile")
        }}<i
          :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }"
          >Corporate Profile</i
        ></span
      >
    </div>

    <!-- 导航 -->
    <section class="ab-item1">
      <!-- <p> -->
      <!-- 跳转 -->
      <!-- <a href="/">{{ $t("aboutus.Home") }}</a> -->
      <!-- {{ $t("aboutus.Corporate Profile") }} -->
      <!-- <a
          v-for="(item, index) in setCrumbs($route.fullPath)"
          :key="index"
          :href="item.crumb"
        >
          <span> ></span> {{ item[$store.state.navLanguageKey] }}
        </a> -->
      <!-- </p> -->
      <bread-crumb />
    </section>
    <div v-html="dataList.content" class="content" id="content"></div>
  </div>
</template>


<script>
import { PageType } from "@/utils/constant";
import { getAction } from "@/api/manage";

export default {
  data() {
    return {
      isshow: false,
      banner: "",
      dataList: {},
      TypeList: {},
    };
  },
  inject: ["setCrumbs"],
  created() {
    
    // 初始化banner
    let that = this;
    getAction("/jeecg-system/common/frontend/advertise/active_list", {
      typeCode: PageType.QIYEJIANJIE,
    }).then((res) => {
      if (
        res.result.records instanceof Array &&
        res.result.records.length > 0
      ) {
        // that.banner = res.result.records[0].sampleImg;
        if (this.$store.state.nowlanguage == "zh")
          that.banner = res.result.records[0].sampleImg;
        if (this.$store.state.nowlanguage == "tr")
          that.banner = res.result.records[0].tranditionalImg;
        if (this.$store.state.nowlanguage == "en")
          that.banner = res.result.records[0].enImg;
      }
    });

    // getAction("/jeecg-system/frontend/articleType/list_all").then((res) => {
    //   for(let i of res.result){
    //     if(i.tranditionalName=='其他'){
    //       this.TypeList=i
    //     }
    //   }
 
    // });

    // 这里的id跟线上内管的企业简介绑定
    // if (this.$store.state.nowlanguage == "zh") {
    //   getAction("/jeecg-system/common/frontend/article/queryById", {
    //     id: "1584742042993430529",
    //     typeId: "1564455746450493441",
    //   }).then((res) => {
    //     console.log(res);
    //     this.dataList = res.result;
    //   });
    // }
    // if (this.$store.state.nowlanguage == "tr") {
    //   getAction("/jeecg-system/common/frontend/article/queryById", {
    //     id: "1585524956152729602",
    //     typeId: "1564455746450493441",
    //   }).then((res) => {
    //     console.log(res);
    //     this.dataList = res.result;
    //   });
    // }
    // if (this.$store.state.nowlanguage == "en") {
    //   getAction("/jeecg-system/common/frontend/article/queryById", {
    //     id: "1585529364596920322",
    //     typeId: "1564455746450493441",
    //   }).then((res) => {
    //     console.log(res);
    //     this.dataList = res.result;
    //   });
    // }
  },
  mounted() {
    
    this.isshow = true;
    if (this.$store.state.nowlanguage == "tr") {
      getAction("/jeecg-system/common/frontend/article/article_frontend_list", {
      languageType: "tranditional",
    }).then((res) => {
     res.result.map(item=>{
        if(item.title=='企業簡介'){
          this.dataList=item
        }
      })
      console.log(this.dataList);
    });
    }
     if (this.$store.state.nowlanguage == "zh") {
      getAction("/jeecg-system/common/frontend/article/article_frontend_list", {
      languageType: "sample",
    }).then((res) => {
     res.result.map(item=>{
        if(item.title=='企业简介'){
          this.dataList=item
        }
      })
      console.log(this.dataList);
    });
    }
     if (this.$store.state.nowlanguage == "en") {
      getAction("/jeecg-system/common/frontend/article/article_frontend_list", {
      languageType: "EN",
    }).then((res) => {
     res.result.map(item=>{
        if(item.title=='Corporate Profile'){
          this.dataList=item
        }
      })
      console.log(this.dataList);
    });
    }
  
  },
};
</script>

<style lang='less' scoped>
.content /deep/ section{
    width: 100%!important;
    box-sizing: border-box;
}
.displaynone {
  display: none;
}
.banner {
  // max-width: 100vw;
  position: relative;
  // min-height: 72vh;
  > img {
    width: 100%;
  }
  > span {
    width: 100%;
    height: 100%;
    font-size: 3rem;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    > i {
      font-size: 2rem;
      font-style: normal;
      opacity: 0.5;
    }
  }
}
/* 英文字体调整 */
.en_fonts09em {
  > span {
    font-size: 0.9em !important;
    text-align: center;
    height: 3.5em !important;
  }
}
/* 英文margin调整 */
.en_margin {
  span {
    margin: 1.8em 0 0 6.3em !important;
  }
}
section {
  // margin: 1.5em auto 0 auto;
  width: 100%;
  // max-width: 1200px;
  // padding: 0 1em;
  // box-sizing: border-box;
}
.ab-item1 {
  padding-bottom: 1.5em;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 1.5em;
  font-size: 1.5rem;
  color: #595757;
}

.ab-item2 {
  margin-top: 0;
  color: #000;
}

.ab-item2 p {
  font-size: 0.9rem;
  line-height: 2;
  margin-bottom: 2em;
  padding: 0 1em;
}

.ab-item2 .hosp {
  display: flex;
  margin-bottom: 2em;
}

.ab-item2 .hosp div {
  width: 21%;
  margin-right: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.6em;
}

.ab-item2 .hosp div:last-child {
  margin-right: 0;
}

.ab-item2 .hosp div a {
  display: block;
  text-align: center;
}
.ab-item2 .hosp div a:hover {
  color: red;
}

.ab-item2 .hosp div a img {
  width: 98%;
}

.ab-item2 .hosp div a span {
  font-size: 0.9em;
  border-bottom: 1px solid #c81a41;
  padding-bottom: 0.6em;
  line-height: 2.6em;
}

.ab-item2 p.abi2-p1 {
  color: #0061ae;
  font-size: 1.6rem;
  margin-bottom: 0.4em;
}

.ab-item2 .zp {
  display: flex;
  margin-bottom: 0.4em;
}

.ab-item2 .zp div {
  width: 49%;
  margin-right: 1%;
  display: flex;
}

.ab-item2 .zp div:last-child {
  margin-right: 0;
}

.ab-item2 .zp div img {
  width: 37em;
}

.ab-item2 .zp div span {
  position: absolute;
  color: #0061ae;
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;
  width: 23em;
  margin: 2.3em 0 0 6.3em;
}

.ab-item2 .zp div span.en {
  margin: 1.8em 0 0 6.3em;
}

.ab-item3 {
  max-width: 100%;
  background: #eee;
  padding: 2.3em 1em;
  margin-bottom: 3em;
}

.ab-item3 .abi3-ly {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.abi3-ly .p1,
.abi3-ly .p4 {
  color: #0061ae;
  font-size: 1.6rem;
  margin-bottom: 0.6em;
}

.abi3-ly .p2 {
  color: #312826;
  font-size: 1.1rem;
  margin-bottom: 0.4em;
  font-weight: 500;
}

.abi3-ly .p3 {
  color: #312826;
  font-size: 0.9rem;
  margin-bottom: 1.4em;
  font-weight: 500;
}

.abi3-ly .d1 {
  display: flex;
  margin: 2em 0 5em 0;
}

.abi3-ly .d1 span {
  width: 24.25%;
  margin-right: 1%;
  background: #0061ae;
  color: #fff;
  font-size: 1.2em;
  border-radius: 30px;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.abi3-ly .d1 span:last-child {
  margin-right: 0;
}

.abi3-ly .d1 span.en {
  font-size: 0.9em;
  text-align: center;
  height: 3.5em;
}

.abi3-ly .d2 {
  display: flex;
  margin: 2em 0 5em 0;
}

.abi3-ly .d2 div {
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: center;
}

.abi3-ly .d2 div img {
  width: 30%;
}

.abi3-ly .d2 div div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.abi3-ly .d2 div div span {
  color: #281e1c;
  font-weight: 500;
  line-height: 1.8em;
  font-size: 1.1rem;
}

.abi3-ly .d2 div div span:last-child {
  font-size: 0.9rem;
}

.ab-item4 .p1 {
  color: #0061ae;
  font-size: 1.6rem;
  margin-bottom: 0.8em;
}

.ab-item4 .d1 {
  display: flex;
  margin-bottom: 4em;
}

.ab-item4 .d1 div {
  width: 32%;
  margin-right: 2%;
  color: #fff;
  background: #c81a41;
  font-size: 1.2em;
  border-radius: 30px;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ab-item4 .d1 div:last-child {
  margin-right: 0;
}

.ab-item4 .d1 div span.en {
  text-align: center;
}

.ab-item4 .d2 {
  background: #0061ae;
  display: none;
  height: 500px;
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .hosp {
    display: none;
  }
  .ab-item1 {
    font-size: 1.2em;
  }

  .ab-item2,
  .ab-item4 {
  }

  .ab-item2 .zp {
    flex-direction: column;
  }

  .ab-item2 .zp div {
    width: 100%;
    display: flex;
  }

  .ab-item3 .abi3-ly {
  }

  .abi3-ly .d1 {
    flex-direction: column;
  }

  .abi3-ly .d1 span {
    width: 100%;
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 768px) {
  .banner {
    max-width: 100vw;
    position: relative;
    // min-height: 30vh;
  }
  .ab-item2 .hosp {
    display: flex;
    flex-direction: column;
  }

  .ab-item2 .hosp div {
    width: 100%;
  }

  .ab-item2 .hosp div span {
    font-size: 1em;
    border-bottom: 1px solid #c81a41;
    margin-bottom: 0.9em;
  }

  .zp div {
    position: relative !important;
  }
  .ab-item2 .zp div img {
    width: 100%;
  }

  .ab-item2 .zp div span {
    box-sizing: border-box;
    font-size: 0.8em;
    width: 100%;
    height: 100%;
    position: absolute;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 50px;
  }

  .abi3-ly .d2 {
    flex-direction: column;
  }

  .abi3-ly .d2 div {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 0.4em;
  }

  .abi3-ly .d2 div img {
    width: 4em;
    margin-right: 0.6em;
  }

  .ab-item4 .d1 {
    flex-direction: column;
    width: 100%;
  }

  .ab-item4 .d1 div {
    width: 100%;
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 480px) {
}
</style>
